.footerItems {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  padding: 0rem 1rem 0rem 1rem;
  @media screen and (min-width: 1124px) {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 2rem;
    align-items: center;
  }
}

.footerContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.125rem;
  @media screen and (min-width: 1124px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.125rem;
  }
}

.footerSelector {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0px;
  margin-top: 4px;
  @media screen and (min-width: 1124px) {
    margin-right: 12px;
    margin-top: 0px;
    margin-bottom: 2px;
  }
}
