.variants {
  width: 100%;
  height: calc(100vh - 230px);
  overflow-y: auto;
  > .variant {
    cursor: pointer;
    &:not(:last-child) {
      border-bottom: 1px solid #d4e0e4;
    }
    &.active {
      color: #46b0c6;
    }
  }
}

.modalContainer {
  max-height: calc(100vh - 130px);
}

.variantsContainer {
  // position: absolute;
  width: 100%;
  // width: 24%;
  // top: 30px;
}

.container {
  max-width: 1080px;
}

.attribute {
  width: calc(100% - 160px);
}

.variantForm {
  max-height: calc(100vh - 130px);
}
