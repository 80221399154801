.line {
  margin: 1.2rem 0 2rem;
  background: none;
  border-bottom: 1px solid #e0e0eb6e;
}

.vline {
  background: none;
  border-right: 1px solid #e0e0eb6e;
  height: 2.5rem;
  padding-left: 0.5rem;
}