.item {
  border: 1px solid #e6f0f3;
  border-radius: 12px;
  padding: 30px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: 20px;

  &.active {
    .image {
      opacity: 1 !important;
    }
    border: 1px solid #46b0c6;
    box-shadow: 0 0 0px 2px rgba(189, 227, 235, 1);
  }

  &:hover {
    .image {
      opacity: 1;
    }
  }
}

.image {
  margin-left: auto;
  margin-right: 0px;
  opacity: 0.5;
  transition: opacity 1s;
  height: 60px;
}

.description {
  margin: auto;
}
