.checkedInBg {
  background-image: url("../../../../images/checked-in-bg.png");
}

.checkedOutBg {
  background-image: url("../../../../images/checked-out-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: inset -20px -20px 20px 4px rgba(255, 255, 255, 0);
}
