.image {
  width: 160px;
}

.field {
  @media screen and (min-width: 640px) {
    width: calc(100% - 160px);
    padding-left: 1rem;
  }
  width: calc(100% - 10%);
  padding-left: 0.25rem;
}

.textarea {
  height: 62px;
  :global {
    textarea {
      height: 62px;
    }
  }
}
