.container {
  padding: 4px 8px;
  border-radius: 12px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  font-size: 12px;
  line-height: 12px;

  &:not(:last-child) {
    margin-right: 0.5rem;
  }
}

.primary {
  color: #69a1b5;
  background: #cfe9f1;
}

.danger {
  color: #fff;
  background: #ff6464;
}

.blue {
  background-color: #deeefc;
  color: #2a8be6;
  border: 1px solid #2a8be6;
  box-sizing: border-box;
  border-radius: 3px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

.orange {
  background-color: #fff3e0;
  color: #ff9900;
  border: 1px solid #ff9900;
  box-sizing: border-box;
  border-radius: 3px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

.gray {
  color: #778083;
  background-color: #f0f1f1;
  border: 1px solid #778083;
  box-sizing: border-box;
  border-radius: 3px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

.yellow {
  background-color: #fdf6e4;
  border: 1px solid #e9bc31;
  box-sizing: border-box;
  border-radius: 3px;
  color: #e9bc31;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

.green {
  background-color: #eafcde;
  color: #78cc44;
  border: 1px solid #78cc44;
  box-sizing: border-box;
  border-radius: 3px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

.red {
  background-color: #fce8e8;
  border: 1px solid #e02d32;
  box-sizing: border-box;
  border-radius: 3px;
  color: #e02d32;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

.reddish {
  background-color: #fce8e8;
  border: 1px solid #f55d58;
  box-sizing: border-box;
  border-radius: 3px;
  color: #f55d58;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

.opaque {
  background-color: #f5f5f5;
  border: 1px solid #cecaca;
  box-sizing: border-box;
  border-radius: 3px;
  color: #d0d0d0;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

.white {
  color: #ffffff;
  background-color: #b9cace;
  box-sizing: border-box;
  border-radius: 3px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}
