.preview {
  background: #ffffff;
  border-radius: 100%;
  text-align: center;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  .app {
    background-color: #f2f6f7;
    height: 100%;
    width: 100%;
    border-radius: 100%;
    margin: auto;
    transition: box-shadow 0.8s;
    overflow: hidden;
  }
  .border {
    color: #46b0c6;
  }
}

.initials {
  padding: 10%;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: center;
  margin: auto;
  span {
    margin: auto;
    font-size: 23px;
    color: #ffffff;
    font-weight: 500;
    display: flex;
  }
}

:global {
  .ant-skeleton-element {
    height: 100%;
    width: 100% !important;
    .ant-skeleton-avatar {
      height: 100%;
      width: 100%;
    }
  }
}
