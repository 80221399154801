.tableItem {
  border-top: 1px solid #e6f0f3;
  padding: 1rem 0;
  padding-left: 1rem;
}

.receiptBackground {
  min-height: 1650px;
  top: 60px;
  bottom: 0;
  right: 0;
  position: absolute;
  background-color: #102d33;
}

.table {
  border-collapse: separate;
  border-spacing: 0px 7px;
  border-bottom: 1px dashed #262f33;
}

.dashedLine {
  border-top: 0px;
  border-bottom: 1px dashed #262f33;
  margin: 10px 0 5px 0;
}
