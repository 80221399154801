.browseInput {
  cursor: pointer !important;
  border-right: none !important;
}

.browseInputError {
  border-color: #c83636;
  color: #c83636;
}

.browseButton:hover,
.browseButton:active,
.browseButton:focus {
  background: linear-gradient(180deg, #fff 0, #f1f1f4 100%);
  border-color: #cbe0e6;
}

.category:hover,
.printable:hover {
  background-color: #e6f0f3;
  .icon {
    visibility: visible;
  }
}

.icon {
  padding: 0;
  visibility: hidden;
}

.iconCat {
  padding: 16px;
}

.printableContainer {
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: -20px;
}
