.columnName {
  @media screen and (max-width: 767px) {
    display: none;
  }
  @media screen and (min-width: 768px) {
    display: grid !important;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    padding: 1rem;
    gap: 1rem;
  }
}

.itemRow {
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  border-top-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  padding: 1rem;
  background-color: #ffffff;
  gap: 1rem;
  > .itemValue:first-child {
    grid-column: span 2 / span 2;
    display: block;
  }
  > .itemValue {
    grid-column: span 1 / span 1;
    place-content: flex-end;
    display: flex;
    align-items: center;
  }
  @media screen and (max-width: 767px) {
    display: block;
    grid-template-columns: none;
    text-align: left;
    > .itemValue {
      place-content: flex-start;
      display: flex;
      justify-content: space-between;
      padding-top: 0.625rem;
      align-items: center;
    }
  }
}

.itemRow:last-child {
  border-bottom-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(243, 244, 246, var(--tw-border-opacity));
}

.itemRow:hover {
  background-color: #eff6f9;
}

.itemLabel {
  display: none;
  @media screen and (max-width: 767px) {
    display: flex;
    justify-content: space-between;
    color: #7a7984;
    font-weight: 600;
    font-size: 12px;
  }
}

.loading {
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  gap: 1rem;
  padding: 1rem;
  text-align: end;
  @media screen and (max-width: 767px) {
    display: block;
    padding: 1rem;
  }
}
