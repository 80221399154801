.container {
  overflow: hidden;
}

:global {
  .support-link {
    //   max-width: 300px;

    .ant-dropdown-menu-item {
      padding: 0px;

      &:hover {
        background-color: transparent;
      }
    }
    &.ant-dropdown-menu {
      border-radius: 4px;
      overflow: hidden;
    }
    &.ant-dropdown-trigger {
      transition: background-color 1s;
      cursor: pointer;
      padding-top: 2px;
    }
    &.ant-dropdown-trigger:hover,
    &.ant-dropdown-trigger.ant-dropdown-open {
      //background: #f5c83e;
    }
  }
}
