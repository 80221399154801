.image {
  border: 1px solid #e6f0f3;
  height: 66px;
  width: 66px;
  border-radius: 4px;
}

.optionName {
  max-width: 50%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.imageLogo {
  height: 100%;
  margin: auto;
}
