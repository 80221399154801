.primary {
  background-color: #46b0c6;
  border: 1px solid #46b0c6;
  color: #ffffff;
  box-shadow: none;
  -webkit-transition: background-color 0.2s ease-in-out;
  -moz-transition: background-color 0.2s ease-in-out;
  -o-transition: background-color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out;

  -webkit-transition: border 0.2s ease-in-out;
  -moz-transition: border 0.2s ease-in-out;
  -o-transition: border 0.2s ease-in-out;
  transition: border 0.2s ease-in-out;
}

.primary:hover {
  background-color: #56b4c7;
  color: #ffffff;
  border: 1px solid #46b0c6;
}

.primary:active,
.primary:focus {
  background-color: #3494a8;
  color: #ffffff;
  border: 1px solid #46b0c6;
}

.secondary {
  font-weight: bold;
  color: #46b0c6;
}

.secondary:hover,
.secondary:active {
  color: #46b0c6;
  border: 1px solid #46b0c6;
  box-shadow: none;
}

.secondary:focus {
  color: #46b0c6;
  outline: none;
  --tw-border-opacity: 1;
  border: 1px solid rgba(229, 231, 235, var(--tw-border-opacity));
  box-shadow: none;
}

.danger {
  background: #ffffff;
  border: 1px solid #e02d32;
  color: #e02d32;
}

.danger:hover {
  border: 1px solid #fc4c51;
  color: #fc4c51;
}

.danger:active,
.danger:focus {
  border: 1px solid #b32428;
  color: #b32428;
}

.ghost {
  color: #73c3d4;
  background-color: transparent;
  border-radius: 3px;
  border: none;
}

.ghost:hover {
  color: #73c3d4;
  border: none;
  background: rgba(28, 79, 90, 0.05);
}

.link:hover,
.link:active,
.link:focus {
  border: none;
  background: transparent;
  box-shadow: none;
  outline: none !important;
  transition: none !important;
}

.plain {
  font-weight: bold;
  color: #ffffff;
}

.plain:hover,
.plain:focus {
  border: 1px solid #ffffff;
  background: #ffffff0e;
  box-shadow: none;
  outline: none !important;
  transition: none !important;
}

.button {
  touch-action: none !important;
  user-select: none !important;
  box-shadow: none !important;
}

.filter {
  border-top: none;
  border-bottom: none;
  border-left: none;
  border-right: solid 1px #cbe0e6;
}

.filter:hover {
  background: white;
}

.buttonLink {
  &:focus {
    outline: none !important;
  }
}
