.venueRow:hover {
  span {
    margin-right: 8px;
  }
}

.venueRow {
  span {
    transition: margin 0.3s;
  }
}
