.item {
  &:first-child {
    border-top: 1px solid #e6f0f3;
  }
  padding: 0.5rem 1.25rem;
  border-bottom: 1px solid #e6f0f3;
}

.tableItem {
  border-top: 1px solid #e6f0f3;
  padding: 1rem 0;
  padding-left: 1rem;
  padding-right: 1rem;
}
