.browseInput {
  background-color: #ffffff;
  cursor: pointer !important;
}

.browseButton:hover,
.browseButton:active,
.browseButton:focus {
  background: linear-gradient(180deg, #fff 0, #f1f1f4 100%);
  border-color: #cbe0e6;
  border-left: 0;
}
