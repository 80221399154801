.container {
  display: flex;
  justify-content: space-between;
  padding: 24px;
}

@media screen and (min-width: 640px) {
  :global {
    .ant-modal-content {
      // width: 600px;
      border-radius: 10px;
      margin: auto;
    }
  }
}

.item {
  border: 2px solid #e6f0f3;
  border-radius: 12px;
  text-align: center;
  width: 47%;
  padding: 30px 20px;
  cursor: pointer;
  transition: border 0.3s;

  &:hover {
    .image {
      opacity: 1;
    }
    border: 2px solid #319bc9;
  }
}

.image {
  margin: auto;
  opacity: 0.5;
  transition: opacity 1s;
  margin-bottom: 20px;
}

.description {
  max-width: 150px;
  margin: auto;
  margin-top: 10px;
}
