.image {
  min-width: 21rem;
}

.mobilePreview {
  position: absolute;
  top: 3rem;
  left: 2.07rem;
  width: 17rem;
  height: 36rem;
  border-radius: 10px;
}

.phoneFrame {
  width: 21rem;
  height: 42.5rem;
  // width: 100%;
  // height: 100%;
}
